import { useDispatch } from 'react-redux';

import { auth as types } from '@codesass/types';

import { useLocation } from '@reach/router';

import * as actions from '../slice';

import AuthForm from './Form';

type State = {
  oobCode?: string;
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const oobCode = (state as State)?.oobCode;

  const resetPassword = ({ password }: types.UserCredential) => {
    dispatch(actions.resetPassword({ oobCode, password }));
  };

  return (
    <AuthForm
      variant="reset-password"
      title="รีเซ็ตรหัสผ่าน"
      onSubmit={resetPassword}
    ></AuthForm>
  );
};

export default ResetPassword;
