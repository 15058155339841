import ResetPassword from 'modules/auth/components/ResetPassword';
import PreventLoggedInGuard from 'modules/auth/components/PreventLoggedInGuard';

const ResetPasswordPage = () => {
  return (
    <PreventLoggedInGuard>
      <ResetPassword></ResetPassword>
    </PreventLoggedInGuard>
  );
};

export default ResetPasswordPage;
